import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import classNames from 'classnames';
import 'react-quill/dist/quill.snow.css';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

export default class TextEditor extends Component {
    handleChange = value => {
        const {
            meta: { touched },
            touch,
            input: { name }
        } = this.props;
        if (value === '<p><br></p>') {
            this.props.change('CreateBlogPostForm', name, '');
            if (!touched) touch(name);
        } else {
            this.props.change('CreateBlogPostForm', name, value);
            if (!touched) touch(name);
        }
    };

    render() {
        const {
            classes,
            meta: { error, touched },
            input,
            touch
        } = this.props;
        const showError = touched && error;
        return (
            <FormControl error={error && !!error.length} required fullWidth>
                <ReactQuill
                    theme={'snow'}
                    onChange={this.handleChange}
                    value={input.value}
                    onBlur={() => {
                        touch('content');
                    }}
                    modules={TextEditor.modules}
                    matchVisual={false}
                    formats={TextEditor.formats}
                    placeholder={this.props.placeholder}
                    className={classNames(classes.textEditor, {
                        [classes.borderError]: showError
                    })}
                />
                {showError && <FormHelperText error={!!error.length}>{error}</FormHelperText>}
            </FormControl>
        );
    }
}

TextEditor.modules = {
    toolbar: [
        [{ header: '1' }, { header: '2' }],
        [{ align: [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link', 'image', 'video']
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false
    }
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
TextEditor.formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
    'align'
];
