import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import BlogPageContainer from '../../containers/blogPage';
import FormsPageContainer from '../../containers/formsPage';
import FormSinglePage from '../../containers/formSinglePage';
import CreateFormContainer from '../../containers/createFormsPage';
import CreateBlobPost from '../../containers/createBlobPost';
import CategoryListContainer from '../../containers/categoryListPage';
import CustomCodesPage from '../../containers/customCodesPage';
import LeftMenu from '../../containers/leftMenu';
import ErrorPage from '../../containers/Error404';

export default class DashboardLayout extends Component {
    switchRoutes = () => (
        <Switch>
            <Route path="/category-list" component={CategoryListContainer} />
            <Route path="/create-new-article" component={CreateBlobPost} />
            <Route exact path="/blog" component={BlogPageContainer} />
            <Route path="/blog/edit/:id" component={CreateBlobPost} />
            <Route exact path="/forms" component={FormsPageContainer} />
            <Route exact path="/forms/edit/:id" component={CreateFormContainer} />
            <Route exact path="/create-form" component={CreateFormContainer} />
            <Route exact path="/custom-codes" component={CustomCodesPage} />
            <Route exact path="/one-form-emails-list/:id" component={FormSinglePage} />
            <Route path="*" component={ErrorPage} />
        </Switch>
    );
    render() {
        return (
            <div>
                <LeftMenu>{this.switchRoutes()}</LeftMenu>
            </div>
        );
    }
}
