import React, {Component, Fragment} from "react";
import {Link} from "react-router-dom";
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import AlertDialogMessage from "../containers/AlertDialogMessage";
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import LastPageIcon from '@material-ui/icons/LastPage';
import classNames from "classnames";

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing.unit * 3,
		overflowX: 'auto',
	},
	table: {
		minWidth: 700,
	},
	icon: {
		cursor: "pointer",
		transition: "0.2s",
		marginLeft: "10px",
		'&:hover': {
			color: "#001cb8",
		}
	},
	tableHeadTd: {
		padding: "10px 50px 10px 25px",
		maxWidth: "300px",
		minWidth: "100px",
		position: "relative",
	},
	subCategoryTitle: {
		paddingLeft: "50px",
	},
	// subCategoryTitleIconHyphen: {
	// 	position: "absolute",
	// 	left: "25px",
	// 	top: "50%",
	// 	transform: "translate(0, -50%)",
	// 	width: "10px",
	// 	height: "2px",
	// 	backgroundColor: "#000"
	// },
	tableFooterRow: {
		textAlign: "center",
		justifyContent: "center",
	},
	titleIconArrow: {
		position: "absolute",
		right: "15px",
		top: "50%",
		transform: "translate(0, -50%)",
		cursor: "pointer",
	},
	submenuRow: {
		display: "none",
	}
});

const actionsStyles = theme => ({
	root: {
		flexShrink: 0,
		color: theme.palette.text.secondary,
		marginLeft: theme.spacing.unit * 2.5,
	},
});

class TablePaginationActions extends React.Component {
	handleFirstPageButtonClick = event => {
		this.props.onChangePage(event, 0);
	};

	handleBackButtonClick = event => {
		this.props.onChangePage(event, this.props.page - 1);
	};

	handleNextButtonClick = event => {
		console.log(event);
		this.props.onChangePage(event, this.props.page + 1);
	};

	handleLastPageButtonClick = event => {
		this.props.onChangePage(
			event,
			Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
		);
	};

	render() {
		const {classes, count, page, rowsPerPage, theme} = this.props;

		return (
			<div className={classes.root}>
				<IconButton
					onClick={this.handleFirstPageButtonClick}
					disabled={page === 0}
					aria-label="First Page"
				>
					{theme.direction === 'rtl' ? <LastPageIcon/> : <FirstPageIcon/>}
				</IconButton>
				<IconButton
					onClick={this.handleBackButtonClick}
					disabled={page === 0}
					aria-label="Previous Page"
				>
					{theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
				</IconButton>
				<IconButton
					onClick={this.handleNextButtonClick}
					disabled={page >= Math.ceil(count / rowsPerPage) - 1}
					aria-label="Next Page"
				>
					{theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
				</IconButton>
				<IconButton
					onClick={this.handleLastPageButtonClick}
					disabled={page >= Math.ceil(count / rowsPerPage) - 1}
					aria-label="Last Page"
				>
					{theme.direction === 'rtl' ? <FirstPageIcon/> : <LastPageIcon/>}
				</IconButton>
			</div>
		);
	}
}

const TablePaginationActionsWrapped = withStyles(actionsStyles, {withTheme: true})(
	TablePaginationActions,
);

class DefaultTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			page: 0,
			rowsPerPage: 10,
			openSubmenuId: null
		}
	}

	handleChangePage = (event, page) => {
		this.setState({page}, () => {
			this.props.getArticleList &&
			this.props.getArticleList(this.state.page + 1, this.state.rowsPerPage);
		});
	};


	onDeleteClick = (e) => {
		this.props.showDeleteMessageState();
		this.setState({deleteElementId: e});
	};

	showSubmenu = (id) => {
		let idSubmenu = this.state.openSubmenuId === id ? null : id;
		this.setState({openSubmenuId: idSubmenu});
	};

	render() {
		const {
			tableContent, classes, buttonText, buttonLink, tableHeader,
			deleteMessageState, hideDeleteMessageState, deleteArticle, articleListCount
		} = this.props;

		return (
			<Fragment>

				{
					deleteMessageState &&
					<AlertDialogMessage
						open={deleteMessageState}
						close={hideDeleteMessageState}
						title={"Do you want to delete the article?"}
						agreeClick={() => deleteArticle(this.state.deleteElementId)}
					/>
				}


				{
					buttonText &&
					<Link to={buttonLink}>
						<Button
							type="submit"
							variant="contained"
							color="primary"
							margin={"normal"}
						>
							{buttonText}
						</Button>
					</Link>
				}
				<Paper className={classes.root}>
					<Table className={classes.table}>
						<TableHead>
							<TableRow>
								{
                                    tableHeader.map(item =>
										<TableCell align={item.align} key={item.id}
										           className={classes.tableHeadTd}>{item.title}</TableCell>
									)
								}
							</TableRow>
						</TableHead>
						<TableBody>
							{tableContent && tableContent.map((row, index) => (
								<Fragment key={row.id}>
									<TableRow>
										<TableCell className={classes.tableHeadTd} component="th" scope="row">
											{(10 * this.state.page) + index + 1}
										</TableCell>
										<TableCell className={classes.tableHeadTd}>
											{row.title}
											{
												row.submenu &&
												(
													this.state.openSubmenuId === row.id ?
														<KeyboardArrowUp
															className={classNames(classes.titleIconArrow,)}
															onClick={() => this.showSubmenu(row.id)}
														/>
														:
														<KeyboardArrowDown
															className={classNames(classes.titleIconArrow,)}
															onClick={() => this.showSubmenu(row.id)}
														/>
												)

											}
										</TableCell>

										<TableCell
											className={classes.tableHeadTd}>{row.publish ? "Published" : "Draft"}</TableCell>

										{row.postsCount ?
											<TableCell className={classes.tableHeadTd}>
												{row.postsCount}
											</TableCell>
											:
											<TableCell className={classes.tableHeadTd}>
												{row.author ? row.author.name : "Do not have author"}
											</TableCell>
										}

										<TableCell className={classes.tableHeadTd} align="right">
											<Delete color="primary" className={classes.icon}
											        onClick={() => this.onDeleteClick(row.id)}/>
											<Link to={`/blog/edit/${row.id}`}>
												<Edit color="primary" className={classes.icon}/>
											</Link>
										</TableCell>
									</TableRow>
									{row.submenu &&
									row.submenu.map((row2, index2) =>
										<TableRow
											key={row2.id}
											className={this.state.openSubmenuId !== row.id && classes.submenuRow}
										>
											<TableCell className={classes.tableHeadTd} component="th" scope="row">
												{(10 * this.state.page) + `${index + 1}.${index2 + 1}`}
											</TableCell>
											<TableCell
												className={classNames(classes.tableHeadTd, classes.subCategoryTitle)}
											>
												<div className={classes.subCategoryTitleIconHyphen}/>
												{row2.title}
											</TableCell>
											<TableCell
												className={classes.tableHeadTd}>{row2.publish ? "Published" : "Draft"}</TableCell>
											<TableCell className={classes.tableHeadTd}>
												{row2.postsCount}
											</TableCell>
											<TableCell className={classes.tableHeadTd} align="right">
												<Delete color="primary" className={classes.icon}
												        onClick={() => this.onDeleteClick(row2.id)}/>
												<Link to={`/blog/edit/${row2.id}`}>
													<Edit color="primary" className={classes.icon}/>
												</Link>
											</TableCell>
										</TableRow>
									)}
								</Fragment>
							))}
						</TableBody>
						{
							articleListCount > this.state.rowsPerPage &&
							<TableFooter>
								<TableRow>
									<TablePagination
										className={classes.tableFooterRow}
										rowsPerPageOptions={[]}
										colSpan={10}
										count={articleListCount ? articleListCount : 0}
										rowsPerPage={this.state.rowsPerPage}
										page={this.state.page}
										SelectProps={{
											native: true,
										}}
										onChangePage={this.handleChangePage}
										ActionsComponent={TablePaginationActionsWrapped}
									/>
								</TableRow>
							</TableFooter>
						}
					</Table>
				</Paper>
			</Fragment>
		);
	}
}

DefaultTable.defaultProps = {
    tableContent: [],
    tableHeader: []
};

export default withStyles(styles)(DefaultTable);
