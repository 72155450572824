export const articleValidate = values => {
    const errors = {};

    if (!values.title || !values.title.trim()) {
        errors.title = 'Required';
    } else if (values.title.length < 2) {
        errors.title = 'Use more than 2 symbols';
    } else if (values.title.length >= 80) {
        errors.title = 'Use less than 80 characters';
    }
    if (!values.metaTitle || !values.metaTitle.trim()) {
        errors.metaTitle = 'Required';
    } else if (values.metaTitle.length < 2) {
        errors.metaTitle = 'Use more than 2 symbols';
    } else if (values.metaTitle.length >= 80) {
        errors.metaTitle = 'Use less than 80 characters';
    }

    if (!values.metaDescription || !values.metaDescription.trim()) {
        errors.metaDescription = 'Required';
    } else if (values.metaDescription && values.metaDescription.length < 2) {
        errors.metaDescription = 'Use more than 2 symbols';
    } else if (values.metaDescription && values.metaDescription.length >= 160) {
        errors.metaDescription = 'Use less than 160 characters';
    }
    if (values.categoriesIds && values.categoriesIds.length === 0) {
        errors.categoriesIds = 'Required';
    }

    if (!values.shortDescription || !values.shortDescription.trim()) {
        errors.shortDescription = 'Required';
    } else if (values.shortDescription && values.shortDescription.length < 2) {
        errors.shortDescription = 'Use more than 2 symbols';
    } else if (values.shortDescription && values.shortDescription.length >= 310) {
        errors.shortDescription = 'Use less than 310 characters';
    }

    if (values.metaDescription && values.metaDescription.length < 2) {
        errors.metaDescription = 'Use more than 2 symbols';
    } else if (values.metaDescription && values.metaDescription.length >= 160) {
        errors.metaDescription = 'Use less than 160 characters';
    }

    if (!values.authorName || !values.authorName.trim()) {
        errors.authorName = 'Required';
    } else if (values.authorName && values.authorName.length < 2) {
        errors.authorName = 'Use more than 2 symbols';
    } else if (values.authorName && values.authorName.length >= 50) {
        errors.authorName = 'Use less than 50 characters';
    }

    if (!values.content || !values.content.trim()) {
        errors.content = 'Required';
    } else if (values.content && values.content.length < 2) {
        errors.content = 'Use more than 2 symbols';
    }

    if (!values.authorPosition || !values.authorPosition.trim()) {
        errors.authorPosition = 'Required';
    } else if (values.authorPosition && values.authorPosition.length < 2) {
        errors.authorPosition = 'Use more than 2 symbols';
    } else if (values.authorPosition && values.authorPosition.length >= 50) {
        errors.authorPosition = 'Use less than 50 characters';
    }
    if (values.categoriesIds && values.categoriesIds.length === 0) {
        errors.categoriesIds = 'Required';
    }

    if (!values.createDate) {
        errors.createDate = 'Required';
    }

    if (!values.publishDate) {
        errors.publishDate = 'Required';
    }

    if (values.quote && values.quote.length < 2) {
        errors.quote = 'Use more than 2 symbols';
    } else if (values.quote && values.quote.length > 200) {
        errors.quote = 'Use less than 200 characters';
    }

    if (!values.canonicalCategory) {
        errors.canonicalCategory = 'Required';
    }

    if (!values.authorPhoto) {
        errors.authorPhoto = 'Required';
    }

    return errors;
};

export const validateCreateFrom = values => {
    const errors = {};

    if (!values.description || !values.description.trim()) {
        errors.description = 'Required';
    } else if (values.description.length < 2) {
        errors.description = 'Use more than 2 symbols';
    } else if (values.description.length > 255) {
        errors.description = 'Use less than 255 characters';
    }

    return errors;
};
