import qs from 'query-string';
import { serverUrl } from '../config';

export const handleFetch = (url, method, value = null) => {
    let queryPath = `${serverUrl}${url}`;
    const options = {
        method,
        headers: {
            'Content-Type': 'application/json',
            Authorization: sessionStorage.getItem('userLogin')
        },
        credentials: 'include',
        mode: 'cors'
    };
    if (value !== null) {
        if (method === 'GET') {
            queryPath += `?${qs.stringify(value)}`;
        } else {
            options.body = JSON.stringify(value);
        }
    }
    return fetch(queryPath, options).then(res => res.json());
};

export const handleFetchImg = url => {
    return fetch(`${serverUrl}${url}`, {
        method: 'GET',
        credentials: 'include',
        mode: 'cors'
    }).then(res => res.status === 200 && res.blob());
};

export const handleDelete = (url, value) => {
    return fetch(`${serverUrl}${url}/${value}`, {
        method: 'DELETE',
        credentials: 'include',
        mode: 'cors'
    }).then(res => res.json());
};

function recursiveParse(obj) {
    const res = {};
    (function recurse(obj, current) {
        for (let key in obj) {
            const value = obj[key];
            const newKey = current ? `${current}[${key}]` : key; // joined key with dot
            if (value && typeof value === 'object') {
                recurse(value, newKey); // it's a nested object, so do it again
            } else {
                res[newKey] = value; // it's not an object, so set the property
            }
        }
    })(obj);
    return res;
}

export const handleUpload = (url, value, method) => {
    const form = new FormData();
    let checkMethod = method || 'POST';

    for (const k in value) {
        if (typeof value[k] !== 'object' || value[k] instanceof File) {
            form.append(k, value[k]);
        } else {
            const parsedObject = recursiveParse(value[k]);
            for (const j in parsedObject) {
                form.append(`${k}[${j}]`, parsedObject[j]);
            }
        }
    }

    return fetch(`${serverUrl}${url}`, {
        method: checkMethod,
        headers: {
            Authorization: sessionStorage.getItem('userLogin')
        },
        body: form,
        credentials: 'include',
        mode: 'cors'
    }).then(res => res.json());
};
