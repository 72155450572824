import React, { Component, Fragment } from 'react';
import { change, Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Edit from '@material-ui/icons/Edit';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';

import DefaultMaterialInput from '../../../componentsGlobal/DefaultMaterialInput';
import DefaultMaterialSelect from '../../../componentsGlobal/DefaultMaterialSelect';
import { getCategoryBlogPage } from '../../../ducks/getCategoryBlogPage';
import { deletePhoto } from '../../../ducks/createNewArticle';
import ImgTopAndPreviewCrop from './imgTopAndPreviewCrop';
import TextEditor from './reactQuillEditor';

const styles = theme => ({
    urlCanonical: {
        marginTop: '-14px',
        marginBottom: '2px'
    },
    selectStyle: {
        marginBottom: '20px'
    },
    blockWithUrl: {
        display: 'flex',
        flexDirection: 'row'
    },
    editIcon: {
        whiteSpace: 'nowrap',
        marginTop: '24px',
        paddingLeft: '20px',
        cursor: 'pointer',
        '&:hover': {
            color: '#000'
        }
    },
    borderError: {
        border: '2px solid #f44336'
    },
    textEditor: {
        marginTop: '7px'
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    chip: {
        margin: theme.spacing.unit / 4
    },
    pageUrlPlaceholder: {
        marginTop: '22px',
        whiteSpace: 'nowrap',
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
        marginBottom: '20px'
    }
});

class CreateBlogPostLeft extends Component {
    state = {
        disableUrl: true
    };

    componentDidMount() {
        this.props.getCategoryBlogPage();
    }

    changePageTitle = e => {
        let value = e.target.value.replace(/[\s]/g, '-');
        value = value.replace(/[!@#$%^&*()+=/?<>.,";:']/g, '');
        this.props.change('CreateBlogPostForm', 'url', value.toLowerCase());
        this.props.change('CreateBlogPostForm', 'metaTitle', value);
    };

    changeDisableUrl = () => {
        this.setState(prevState => ({ disableUrl: !prevState.disableUrl }));
    };

    categoryNameToId = name => {
        if (!name) return;
        const canonicalUrlSelectedValues = this.getSelectedCategories();
        const selected =
            canonicalUrlSelectedValues &&
            name &&
            canonicalUrlSelectedValues.find(cat => name === cat.name);
        const result = selected || {};
        return result;
    };

    getCategoryName = category => {
        if (!category || !category.id || !category.name) return '';
        return category.name;
    };

    getSelectedCategories = () => {
        const { categoryBlogList, categoriesIds } = this.props;
        const canonicalUrlSelectedValues =
            categoryBlogList &&
            categoriesIds &&
            categoryBlogList.filter(category => categoriesIds.some(el => el === category.id));
        return canonicalUrlSelectedValues;
    };

    generateStaticUrl = () => {
        const { canonicalCategory } = this.props;
        let categorieUrl = canonicalCategory
            ? this.getCategoryName(canonicalCategory)
                  .toLowerCase()
                  .replace(/ /g, '-')
            : 'subcategory';

        return `blog/${categorieUrl}/`;
    };

    render() {
        const {
            classes,
            pageUrl,
            change,
            categoryBlogList,
            categoriesIds,
            touch,
            previewCrop,
            topCrop,
            coverId,
            deletePhoto
        } = this.props;

        const canonicalUrlSelectedValues = this.getSelectedCategories();

        const halfCanonicalURL = this.generateStaticUrl();

        return (
            <Fragment>
                <Field
                    name="title"
                    type="text"
                    labelText="Page title"
                    component={DefaultMaterialInput}
                    onChange={this.changePageTitle}
                    required
                />

                <Field
                    name="categoriesIds"
                    component={DefaultMaterialSelect}
                    label="Category"
                    multiple
                    require
                    classes={classes}
                    className={classes.selectStyle}
                    handleChange={this.handleChange}
                    multipleArray={categoryBlogList}
                    formControlProps={{
                        fullWidth: true
                    }}
                >
                    {categoryBlogList.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                            <Checkbox
                                checked={categoriesIds && categoriesIds.indexOf(item.id) > -1}
                                color="primary"
                            />
                            {item.name}
                        </MenuItem>
                    ))}
                </Field>

                <Field
                    disableSelect={categoriesIds ? categoriesIds.length < 1 : false}
                    name="canonicalCategory"
                    component={DefaultMaterialSelect}
                    label="Subcategory for canonical URL"
                    required
                    shrink
                    classes={classes}
                    className={classes.selectStyle}
                    formControlProps={{
                        fullWidth: true
                    }}
                    format={this.getCategoryName}
                    normalize={this.categoryNameToId}
                >
                    {canonicalUrlSelectedValues &&
                        canonicalUrlSelectedValues.map(item => (
                            <MenuItem key={item.id} value={item.name}>
                                {item.name}
                            </MenuItem>
                        ))}
                </Field>

                <FormControl fullWidth className={classes.blockWithUrl}>
                    <div
                        className={classes.pageUrlPlaceholder}
                    >{`${pageUrl}${halfCanonicalURL}`}</div>
                    <Field
                        name="url"
                        type="text"
                        shrink
                        labelText="Canonical URL"
                        component={DefaultMaterialInput}
                        disabled={this.state.disableUrl}
                    />
                    <div className={classes.editIcon} onClick={this.changeDisableUrl}>
                        <Edit />
                    </div>
                </FormControl>
                {previewCrop && topCrop && (
                    <Field
                        name="cover"
                        type="file"
                        change={change}
                        coverId={coverId}
                        title={'Cover photo'}
                        component={ImgTopAndPreviewCrop}
                        previewCrop={previewCrop}
                        topCrop={topCrop}
                        deletePhoto={deletePhoto}
                        touchOnBlur={true}
                    />
                )}

                <Field
                    name="shortDescription"
                    type="text"
                    labelText="Short Description"
                    component={DefaultMaterialInput}
                    fullWidth
                    multiline
                    required
                    rows={6}
                    rowsMax={15}
                />

                <Field
                    name="quote"
                    type="text"
                    labelText="Quote"
                    component={DefaultMaterialInput}
                    fullWidth
                    multiline
                    rows={6}
                    rowsMax={15}
                />

                <Typography component={'h4'} variant={'h6'}>
                    Article Text
                </Typography>
                <Field
                    name="content"
                    type="text"
                    required
                    touch={touch}
                    placeholder={'Write something'}
                    value={this.props.initialValues.content}
                    component={TextEditor}
                    change={change}
                    classes={classes}
                />
            </Fragment>
        );
    }
}

const selector = formValueSelector('CreateBlogPostForm');

const mapStateToProps = state => {
    return {
        pageUrl: state.createNewArticle.resetEditBlogPostForm.pageUrl,
        categoryBlogList: state.getCategoryBlogPage.categoryBlogList,
        initialValues: state.createNewArticle.resetEditBlogPostForm,
        categoriesIds: selector(state, 'categoriesIds'),
        categorieForCanonicalUrl: selector(state, 'categorieForCanonicalUrl'),
        canonicalCategory: selector(state, 'canonicalCategory'),
        previewCrop: selector(state, 'previewCrop'),
        topCrop: selector(state, 'topCrop'),
        coverId: selector(state, 'coverId')
    };
};

const mapDispatchToPros = {
    getCategoryBlogPage,
    change,
    deletePhoto
};

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToPros
    )(CreateBlogPostLeft)
);
