import React, { Component, Fragment } from 'react';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import OneListItem from './OneListItem';
import PropTypes from 'prop-types';

export default class ListItemWithSubmenu extends Component {
    render() {
        const {
            item,
            item: { submenu },
            classes,
            openSubmenuId,
            changeOpenSubmenuId
        } = this.props;

        return (
            <Fragment>
                <OneListItem
                    item={item}
                    changeOpenSubmenuId={changeOpenSubmenuId}
                    openSubmenuId={openSubmenuId}
                    classes={classes}
                />
                <Collapse
                    in={openSubmenuId === item.id ? true : false}
                    timeout="auto"
                    unmountOnExit
                >
                    <List component="div" disablePadding>
                        {submenu.map(item => (
                            <OneListItem
                                key={item.id}
                                item={item}
                                classes={classes}
                                className={classes.submenuItem}
                            />
                        ))}
                    </List>
                </Collapse>
            </Fragment>
        );
    }
}

ListItemWithSubmenu.propTypes = {
    item: PropTypes.shape({
        path: PropTypes.string,
        name: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
    }),
    classes: PropTypes.shape({ submenuItem: PropTypes.string }),
    openSubmenuId: PropTypes.number,
    changeOpenSubmenuId: PropTypes.func
};
