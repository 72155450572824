import {handleFetch, handleDelete} from "../util/fetch";

const initialState = {
	articleList: [],
	deleteMessageState: false
};

const GET_ARTICLE_LIST = "GET_ARTICLE_LIST";
const SHOW_DELETE_MESSAGE = "SHOW_DELETE_MESSAGE";
const HIDE_DELETE_MESSAGE = "HIDE_DELETE_MESSAGE";

export const showDeleteMessageState = deleteMessageState => ({
	type: SHOW_DELETE_MESSAGE,
	deleteMessageState,
});

export const hideDeleteMessageState = deleteMessageState => ({
	type: HIDE_DELETE_MESSAGE,
	deleteMessageState,
});

export const getArticleList = (page, perPage) => dispatch => {
	let tableDate = {page: page ? page : 1, perPage: perPage ? perPage : 10};
	handleFetch("/protected/article", "GET", tableDate)
		.then(res => {
			if (res.success) {
				dispatch({
					type: GET_ARTICLE_LIST,
					res,
				});
			} else throw res.payload;
		})
		.catch(err => console.log("GET_CATEGORY_FAILED", err));
};

export const deleteArticle = parameters => dispatch => {
	handleDelete("/article", parameters)
		.then(res => {
			if (res.success) {
				dispatch(getArticleList());
			} else throw res.payload;
		})
		.catch(err => console.log("DELETE_ARTICLE", err));
};

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_ARTICLE_LIST:
			return {
				...state,
				articleList: action.res.payload,
			};

		case SHOW_DELETE_MESSAGE:
			return {
				...state,
				deleteMessageState: true
			};

		case HIDE_DELETE_MESSAGE:
			return {
				...state,
				deleteMessageState: false
			};

		default:
			return state;
	}
};
