import React from 'react';
import {Redirect, Route} from 'react-router-dom';

const PrivateRoute = ({ component: Component, props, ...rest }) => (
	<Route {...rest} render={(props) => (
		sessionStorage.getItem("userLogin") === "true"
			? <Component {...props} />
			: <Redirect to={{
				pathname: '/login',
				state: { from: props.location }
			}} />
	)} />
);


export default PrivateRoute;