import React, {Component} from "react";
import {withStyles} from '@material-ui/core/styles';
import {connect} from "react-redux";

import DefaultTable from "../../../componentsGlobal/DefaultTable";
import {getArticleList, deleteArticle, showDeleteMessageState, hideDeleteMessageState} from "../../../ducks/blogPosts";

const tableHeader = [
	{
		id: 1,
		title: "#"
	},
	{
		id: 2,
		title: "Title"
	},
	{
		id: 3,
		title: "Status"
	},
	{
		id: 4,
		title: "Author"
	},
	{
		id: 5,
		title: "Actions",
		align: "right",
	}
];

const styles = theme => ({});

class BlogPage extends Component {
	render() {
		const {
			articleList, deleteArticle, deleteMessageState, showDeleteMessageState, hideDeleteMessageState,
			articleListCount, getArticleList
		} = this.props;

		return (
			<DefaultTable
				buttonText={"Add new article"}
				buttonLink={"/create-new-article"}
				tableHeader={tableHeader}
				tableContent={articleList}
				deleteArticle={deleteArticle}
				deleteMessageState={deleteMessageState}
				showDeleteMessageState={showDeleteMessageState}
				hideDeleteMessageState={hideDeleteMessageState}
				articleListCount={articleListCount}
				getArticleList={getArticleList}
			/>
		);
	}
}

const mapStateToProps = state => ({
	deleteMessageState: state.blogPosts.deleteMessageState,
	articleList: state.blogPosts.articleList.entities,
	articleListCount: state.blogPosts.articleList.count,
});
const mapDispathToProps = {
	getArticleList,
	deleteArticle,
	showDeleteMessageState,
	hideDeleteMessageState,
};

export default connect(mapStateToProps, mapDispathToProps)(withStyles(styles)(BlogPage));
