import React from 'react';
import { Route, Switch } from 'react-router-dom';

import SignIn from '../../containers/signin';
import ErrorPage from "../../containers/Error404";

const SignInLayout = () => (
	<div>
		<Switch>
			<Route exact path="/login" component={SignIn}/>
			<Route path="*" component={ErrorPage}/>
		</Switch>
	</div>
);

export default SignInLayout;
