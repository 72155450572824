import {handleFetch, handleUpload, handleDelete} from "../util/fetch";
import {reset} from "redux-form";

const initialState = {
	forms: [],
	oneForm: null,
	formHistory: null,
	congratulationFromMessage: false,
	deleteFormMessageState: false,
};

const GET_FORMS = "GET_FORMS";
const GET_ONE_FORM = "GET_ONE_FORM";
const CREATE_FORM = "CREATE_FORM";
const HIDE_MESSAGE = "HIDE_MESSAGE";
const CLEAR_CREATE_FORM = "CLEAR_CREATE_FORM";
const SHOW_DELETE_FORM_MESSAGE = "SHOW_DELETE_FORM_MESSAGE";
const HIDE_DELETE_FORM_MESSAGE = "HIDE_DELETE_FORM_MESSAGE";
const GET_FORM_HISTORY = "GET_FORM_HISTORY";


export const hideCongratulationFromMessage = congratulationFromMessage => ({
	type: HIDE_MESSAGE,
	congratulationFromMessage,
});

export const clearCreateForm = () => ({
	type: CLEAR_CREATE_FORM,
});

export const showDeleteFormMessageState = deleteFormMessageState => ({
	type: SHOW_DELETE_FORM_MESSAGE,
	deleteFormMessageState,
});

export const hideDeleteFormMessageState = deleteFormMessageState => ({
	type: HIDE_DELETE_FORM_MESSAGE,
	deleteFormMessageState,
});


export const getForms = () => dispatch => {
	handleFetch("/feedback", "GET")
		.then(res => {
			if (res.success) {
				dispatch({
					type: GET_FORMS,
					res,
				});
			} else throw res.payload;
		})
		.catch(err => console.log("GET_FORMS_FAILED", err));
};

export const getOneForm = (id) => dispatch => {
	handleFetch(`/feedback`, "GET")
		.then(async res => {
			await dispatch({
				type: GET_FORMS,
				res,
			});
			return res;
		})
		.then(res => {
			dispatch({
				type: GET_ONE_FORM,
				res,
				id,
			});
		})
		.catch(err => console.log("GET_ARTICLE_FAILED", err));
};

export const deleteForm = (parameters) => dispatch => {
	handleDelete("/feedback", parameters)
		.then(res => {
			if (res.success) {
				dispatch(getForms());
			} else throw res.payload;
		})
		.catch(err => console.log("DELETE_ARTICLE", err));
};

export const createForm = (formName) => dispatch => {
	handleUpload("/feedback", formName)
		.then(res => {
			if (res.success) {
				dispatch(reset('CreateForm'));
				dispatch({
					type: CREATE_FORM,
					res,
				});
			} else throw res.payload;
		})
		.catch(err => console.log("GET_FORMS_FAILED", err));
};

export const getFormHistory = (params) => dispatch => {
	handleFetch(`/feedback/history?page=1&perPage=10&search=&formId=${params}`, "GET")
		.then(res => {
			if (res.success) {
				dispatch({
					type: GET_FORM_HISTORY,
					res,
				});
			} else throw res.payload;
		})
		.catch(err => console.log("GET_FORM_HISTORY_FAILED", err));
};

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_FORMS:
			return {
				...state,
				forms: action.res.payload,
			};

		case CREATE_FORM:
			return {
				...state,
				congratulationFromMessage: true
			};

		case HIDE_MESSAGE:
			return {
				...state,
				congratulationFromMessage: false
			};

		case GET_FORM_HISTORY:
			return {
				...state,
				formHistory: action.res.payload
			};

		case SHOW_DELETE_FORM_MESSAGE:
			return {
				...state,
				deleteFormMessageState: true
			};

		case HIDE_DELETE_FORM_MESSAGE:
			return {
				...state,
				deleteFormMessageState: false
			};

		case CLEAR_CREATE_FORM:
			return {
				...state,
				oneForm: null,
				congratulationFromMessage: false,
			};

		case GET_ONE_FORM:
			let formsArray = action.res.payload;
			let oneForm = formsArray.find(item => +item.id === +action.id);

			return {
				...state,
				oneForm: oneForm
			};


		default:
			return state;
	}
};
