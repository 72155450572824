import React, { Component, Fragment } from 'react';
import { change, Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';

import ErrorField from '../../../componentsGlobal/DefaultMaterialInput';
import DefaultMaterialSelect from '../../../componentsGlobal/DefaultMaterialSelect';
import ImgOpengrafCrop from './imgOpengrafCrop';
import { deletePhoto } from '../../../ducks/createNewArticle';

const styles = theme => ({});

class CreateBlogPostCenter extends Component {
    render() {
        const { classes, change, opengraphCrop, opengraphId, deletePhoto } = this.props;
        return (
            <Fragment>
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Typography
                            component="h4"
                            variant="h5"
                            color="inherit"
                            className={classes.title}
                        >
                            SEO Parameters
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Field
                            name="metaTitle"
                            type="text"
                            labelText="Meta title tag"
                            component={ErrorField}
                            required
                        />
                        <Field
                            name="metaDescription"
                            type="text"
                            labelText="Meta description"
                            required
                            showMultipleLine
                            component={ErrorField}
                            fullWidth
                            multiline
                            rows={6}
                            rowsMax={15}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Field
                            name="selectIndex"
                            component={DefaultMaterialSelect}
                            label="Index"
                            classes={classes}
                            formControlProps={{
                                fullWidth: true
                            }}
                        >
                            <MenuItem value={'index'}>Index</MenuItem>
                            <MenuItem value={'index follow'}>Index follow</MenuItem>
                            <MenuItem value={'noindex'}>No index</MenuItem>
                            <MenuItem value={'noindex nofollow'}>No index no follow</MenuItem>
                        </Field>
                    </Grid>
                    <Grid item xs={3}>
                        <Field
                            name="opengraph"
                            type="file"
                            change={change}
                            title={'OG Cover photo'}
                            component={ImgOpengrafCrop}
                            opengraphCrop={opengraphCrop}
                            opengraphId={opengraphId}
                            deletePhoto={deletePhoto}
                            touchOnBlur={true}
                        />
                    </Grid>
                </Grid>
            </Fragment>
        );
    }
}

const selector = formValueSelector('CreateBlogPostForm');

const mapStateToProps = state => ({
    opengraphCrop: selector(state, 'opengraphCrop'),
    opengraphId: selector(state, 'opengraphId')
});

const mapDispatchToPros = {
    change,
    deletePhoto
};

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToPros
    )(CreateBlogPostCenter)
);
