import React, {Component} from "react";

import CreateEditForms from "./components/CreateEditForm";

class CreateFormContainer extends Component {
	render() {
		return (
			<CreateEditForms params={this.props.match.params}/>
		);
	}
}

export default CreateFormContainer;
