import React, { Component, Fragment } from 'react';
import ReactCrop from 'react-image-crop';
import { compose } from 'redux';
import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import 'react-image-crop/dist/ReactCrop.css';
import classNames from 'classnames';

const coverIcon = require('../../../images/cover3.png');

const styles = theme => ({
    coverBlock: {
        marginBottom: '20px'
    },
    cropContainer: {
        marginBottom: '20px'
    },
    coverImg: {
        width: '100%',
        height: '270px',
        overflow: 'hidden',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        marginBottom: '20px',
        marginTop: '12px'
    },
    coverImgError: {
        border: '2px solid red'
    },
    inputForCrop: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        opacity: '0',
        cursor: 'pointer',
        zIndex: 2
    },
    errorText: {
        marginBottom: '10px',
        marginTop: '-10px',
        fontSize: '0.75rem'
    },
    btnsBlock: {
        display: 'flex',
        justifyContent: 'space-between'
    }
});

class ImgTopAndPreviewCrop extends Component {
    imageHeight;
    imageWidth;
    state = {
        updatedWithTrueValues: false,
        coverExists: false,
        topCrop: {
            width: 100,
            x: 0,
            y: 0,
            aspect: 16 / 9
        },
        previewCrop: {
            height: 100,
            x: 0,
            y: 0,
            aspect: 3 / 2
        }
    };

    onImageLoaded = image => {
        this.imageRef = image;
        this.imageHeight = this.imageRef.naturalHeight;
        this.imageWidth = this.imageRef.naturalWidth;
    };

    getPercents = pixelCrop => {
        const percentCrop = { ...pixelCrop };
        percentCrop.width = (pixelCrop.width / this.imageWidth) * 100;
        percentCrop.height = (pixelCrop.height / this.imageHeight) * 100;
        percentCrop.x = (pixelCrop.x / this.imageWidth) * 100;
        percentCrop.y = (pixelCrop.y / this.imageHeight) * 100;
        return percentCrop;
    };

    onCropChangeTop = (crop, pixelCrop) => {
        if (!this.state.coverExists) {
            this.setState({ coverExists: true });
            return;
        }
        !!pixelCrop.height &&
            !!pixelCrop.width &&
            this.setState({ topCrop: crop, pixelCropTop: pixelCrop });
    };

    onCropChangePreview = (crop, pixelCrop) => {
        if (!this.state.coverExists) {
            this.setState({ coverExists: true });
            return;
        }
        !!pixelCrop.height &&
            !!pixelCrop.width &&
            this.setState({ previewCrop: crop, pixelCropPreview: pixelCrop });
    };

    onCropTopBlur = () => {
        this.props.change('CreateBlogPostForm', 'topCrop', this.state.pixelCropTop);
    };

    onCropPreviewBlur = () => {
        this.props.change('CreateBlogPostForm', 'previewCrop', this.state.pixelCropPreview);
    };

    componentDidUpdate() {
        const { topCropped, previewCropped } = this.props;
        if (!this.state.updatedWithTrueValues && this.imageHeight && this.imageWidth) {
            this.setState({
                updatedWithTrueValues: true,
                topCrop: this.getPercents(topCropped),
                previewCrop: this.getPercents(previewCropped)
            });
        }
    }

    deleteCoverImg = () => {
        this.props.change('CreateBlogPostForm', 'cover', null);
        this.inputTypeFile.value = null;
        if (this.props.coverId) {
            this.props.deletePhoto(this.props.coverId);
            this.props.change('CreateBlogPostForm', 'coverId', false);
        }
    };

    render() {
        const { topCrop, previewCrop } = this.state;
        const {
            classes,
            meta: { error, touched }
        } = this.props;

        const inputFile = this.props.input.value;
        const coverSrc =
            inputFile &&
            (typeof inputFile === 'object' ? URL.createObjectURL(inputFile) : inputFile);
        return (
            <div className={classes.coverBlock}>
                <Typography component={'h4'} variant={'h5'}>
                    {this.props.title}
                </Typography>

                {coverSrc ? (
                    <div className={classes.cropContainer}>
                        <Fragment>
                            <div onBlur={this.onCropTopBlur} className={classes.cropContainer}>
                                <Typography component={'h5'} variant={'h6'}>
                                    Preview top photo
                                </Typography>
                                <ReactCrop
                                    ref={ref => (this.topCropRef = ref)}
                                    src={coverSrc}
                                    crop={topCrop}
                                    onImageLoaded={this.onImageLoaded}
                                    onChange={this.onCropChangeTop}
                                />
                            </div>
                            <div onBlur={this.onCropPreviewBlur} className={classes.cropContainer}>
                                <Typography component={'h5'} variant={'h6'}>
                                    Preview slider photo
                                </Typography>
                                <ReactCrop
                                    ref={ref => (this.previewCropRef = ref)}
                                    src={coverSrc}
                                    crop={previewCrop}
                                    onImageLoaded={this.onImageLoaded}
                                    onChange={this.onCropChangePreview}
                                />
                            </div>
                        </Fragment>
                    </div>
                ) : (
                    <div
                        className={classNames(
                            classes.coverImg,
                            error && touched ? classes.coverImgError : ''
                        )}
                        style={{ backgroundImage: `url(${coverSrc || coverIcon})` }}
                    />
                )}

                <div className={coverSrc && classes.btnsBlock}>
                    <Button variant="contained" color="primary">
                        <input
                            type="file"
                            onChange={e => this.props.input.onChange(e.target.files[0])}
                            ref={el => (this.inputTypeFile = el)}
                            className={classes.inputForCrop}
                        />
                        {coverSrc ? 'Change image' : 'Upload'}
                    </Button>
                    {coverSrc && (
                        <Button variant="contained" color="primary" onClick={this.deleteCoverImg}>
                            Delete
                        </Button>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({
    form: {
        CreateBlogPostForm: {
            values: { topCrop, previewCrop }
        }
    }
}) => {
    return {
        topCropped: topCrop,
        previewCropped: previewCrop
    };
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps)
)(ImgTopAndPreviewCrop);
