import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
	main: {
	    minHeight: "calc(100vh - 150px)",
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: "center",
	},
	paper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
        justifyContent: "center",
        margin: "auto",
        minWidth: "400px",
        minHeight: "300px",
		padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
	},
});

function ErrorPage(props) {
	const { classes } = props;

	return (
		<main className={classes.main}>
			<CssBaseline />
			<Paper className={classes.paper}>
				<Typography component="h1" variant="h1">
					404
				</Typography>
			</Paper>
		</main>
	);
}

export default withStyles(styles)(ErrorPage);
