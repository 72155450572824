import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import SignInLayout from './layouts/SignIn';
import DashboardLayout from './layouts/Dashboard';
import PrivateRoute from './private-route';

const styles = theme => ({
    '@global': {
        body: {
            margin: 0,
            padding: 0,
            webkitFontSmoothing: 'antialiased',
            mozOsxFontSmoothing: 'grayscale',
            width: '100%',
            minWwidth: '280px',
            webkitTextSizeAdjust: '100%',
            wordBreak: 'break-word',
            wordWrap: 'break-word',
            color: '#000',
            fontFamily: 'Roboto, sans-serif',
            lineHeight: '1.2',
            position: 'relative',
            minHeight: '100vh'
        },

        a: {
            textDecoration: 'none'
        },

        img: {
            maxWidth: '100%',
            height: 'auto'
        },

        div: {
            '&.ql-editor': {
                minHeight: '200px',
                maxHeight: '500px'
            },
            '&.quill': {
                marginTop: 0
            },
            '&.wrapper': {
                position: 'relative',
                maxWidth: '1024px',
                minWidth: '280px',
                margin: 'auto',
                padding: '0px 20px',
                boxSizing: 'border-box'
            }
        },

        'html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary,time, mark, audio, video': {
            margin: 0,
            padding: 0,
            border: 0,
            fontSize: '100%',
            verticalAlign: 'aseline',
            webkitTextSizeAdjust: '100%'
        }
    }
});

class MainRoute extends Component {
    render() {
        return (
            <Switch>
                <Route path="/login" component={SignInLayout} />
                <PrivateRoute path="/" component={DashboardLayout} />
            </Switch>
        );
    }
}

export default withStyles(styles)(MainRoute);
