import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import MainListItems from './components/MainListItems';

const drawerWidth = 270;

const styles = theme => ({
    root: {
        display: 'flex'
    },
    toolbar: {
        paddingRight: 24 // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar
    },
    appBar: {
        position: 'fixed',
        right: 0,
        top: 0,
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36
    },
    menuButtonHidden: {
        display: 'none'
    },
    title: {
        flexGrow: 1
    },
    drawerPaper: {
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        height: '100vh',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        width: theme.spacing.unit * 7,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing.unit * 9
        }
    },
    appBarSpacer: {
        ...theme.mixins.toolbar
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
        height: '100vh',
        paddingTop: '100px',
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        overflowX: 'hidden'
    },
    contentInside: {
        margin: 'auto',
        maxWidth: '1000px'
    },
    chartContainer: {
        marginLeft: -22
    },
    tableContainer: {
        height: 320
    },
    h5: {
        marginBottom: theme.spacing.unit * 2
    }
});

class LeftMenu extends React.Component {
    state = {
        leftMenuOpen: true,
	    openSubmenuId: null
    };

    handleDrawerOpen = () => {
        this.setState({ leftMenuOpen: true });
    };

    handleDrawerClose = () => {
        this.setState({ leftMenuOpen: false, openSubmenuId: null });
    };

	changeOpenSubmenuId = id => {
		let idSubmenu = this.state.openSubmenuId === id ? null : id;
		this.setState({ openSubmenuId: idSubmenu });
	};

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <CssBaseline />
                <AppBar
                    position="absolute"
                    className={classNames(
                        classes.appBar,
                        this.state.leftMenuOpen && classes.appBarShift
                    )}
                >
                    <Toolbar disableGutters={!this.state.leftMenuOpen} className={classes.toolbar}>
                        <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            onClick={this.handleDrawerOpen}
                            className={classNames(
                                classes.menuButton,
                                this.state.leftMenuOpen && classes.menuButtonHidden
                            )}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            className={classes.title}
                        >
                            Admin page
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="permanent"
                    classes={{
                        paper: classNames(
                            classes.drawerPaper,
                            !this.state.leftMenuOpen && classes.drawerPaperClose
                        )
                    }}
                    open={this.state.leftMenuOpen}
                >
                    <div className={classes.toolbarIcon}>
                        <IconButton onClick={this.handleDrawerClose}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>
                    <Divider />
                    <MainListItems
	                    leftMenuOpen={this.state.leftMenuOpen}
	                    changeOpenSubmenuId={this.changeOpenSubmenuId}
	                    openSubmenuId={this.state.openSubmenuId}
                    />
                </Drawer>
                <main className={classes.content}>
                    <div className={classes.contentInside}>{this.props.children}</div>
                </main>
            </div>
        );
    }
}

LeftMenu.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(LeftMenu);
