import {handleFetch} from "../util/fetch";

const initialState = {
	failed: false,
	error: "",
	email: "",
	password: "",

	success: false,
};

const CHANGE_EMAIL = "CHANGE_EMAIL";
const CHANGE_PASSWORD = "CHANGE_PASSWORD";

const SUBMIT_LOGIN_SUCCESS = "SUBMIT_LOGIN";
const SUBMIT_LOGIN_FAILED = "SUBMIT_LOGIN_FAILED";

export const changeEmail = email => ({
	type: CHANGE_EMAIL,
	email,
});

export const changePassword = password => ({
	type: CHANGE_PASSWORD,
	password,
});

export const submitLogin = (email, password) => dispatch => {
    handleFetch("/login", "POST", {email, password})
        .then(res => {
            if (res.success) {
                dispatch({
                    type: SUBMIT_LOGIN_SUCCESS,
                    res,
                });
            } else throw res.payload;
        })
        .catch(err => dispatch({ type: SUBMIT_LOGIN_FAILED, err }));
};

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case CHANGE_EMAIL:
			return {
				...state,
				email: action.email,
			};

		case CHANGE_PASSWORD:
			return {
				...state,
				password: action.password,
			};

		case SUBMIT_LOGIN_SUCCESS:
			sessionStorage.setItem('userLogin', true);
			return {
				...state,
				failed: false,
				error: "",
				success: true,
				email: "",
				password: "",
			};

		case SUBMIT_LOGIN_FAILED:
			return {
				...state,
				failed: true,
				error: action.err,
				success: false,
			};

		default:
			return state;
	}
};
