import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import {withStyles} from '@material-ui/core/styles';

import MySnackbarContentWrapper from "./NotificationWrap";

const styles = theme => ({
	margin: {
		margin: theme.spacing.unit,
	},
});

class Notification extends React.Component {
	handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		this.props.close();
	};

	render() {
		const {openState, variant, messageText} = this.props;

		return (
			<div>
				<Snackbar
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					open={openState}
					autoHideDuration={1000}
					onClose={this.handleClose}
				>
					<MySnackbarContentWrapper
						onClose={this.handleClose}
						variant={variant}
						message={messageText}
					/>
				</Snackbar>

			</div>
		);
	}
}

export default withStyles(styles)(Notification);
