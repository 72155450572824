import React, {Component} from "react";
import {connect} from "react-redux";

import ViewForm from "./components/form";

import {
	getForms,
	showDeleteFormMessageState,
	hideDeleteFormMessageState,
	deleteForm
} from "../../ducks/getAndCreateForms";

const formTableHeader = [
	{
		id: 1,
		title: "Form ID"
	},
	{
		id: 2,
		title: "Form Name"
	},
	{
		id: 3,
		title: "Number of incoming emails"
	},
	{
		id: 4,
		title: "Actions",
		align: "right",
	},
];

class FormsPageContainer extends Component {
	componentDidMount() {
		this.props.getForms();
	}

	render() {
		const {forms, deleteFormMessageState, hideDeleteFormMessageState, showDeleteFormMessageState, deleteForm} = this.props;

		return (
			<ViewForm
				tableHeader={formTableHeader}
				tableContent={forms}
				buttonText={"Add new form"}
				buttonLink={"/create-form"}
				deleteForm={deleteForm}
				articleListCount={10}
				deleteFormMessageState={deleteFormMessageState}
				hideDeleteFormMessageState={hideDeleteFormMessageState}
				showDeleteFormMessageState={showDeleteFormMessageState}
			/>
		);
	}
}

const mapStateToProps = state => ({
	forms: state.getAndCreateForms.forms,
	deleteFormMessageState: state.getAndCreateForms.deleteFormMessageState,
});

const mapDispatchToPros = {
	getForms,
	showDeleteFormMessageState,
	hideDeleteFormMessageState,
	deleteForm,
};

export default connect(mapStateToProps, mapDispatchToPros)(FormsPageContainer);