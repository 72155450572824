import React from 'react';
import MinimizeIcon from '@material-ui/icons/Minimize';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import history from '../../../history';

export default function OneListItem({ item, changeOpenSubmenuId, className, classes, openSubmenuId }) {
	const itemClick = () => {
		history.push(item.path);
		if (changeOpenSubmenuId) {
			changeOpenSubmenuId(item.id);
		}
	};

	const locationUrl = window.location.pathname;

	return (
		<ListItem
			button
			key={item.id}
			onClick={itemClick}
			selected={locationUrl === item.path ? true : false}
			className={classNames(classes.menuItem, className ? className : '')}
		>
			{item.icon && (
				<ListItemIcon>
					<item.icon />
				</ListItemIcon>
			)}
			<ListItemText primary={item.name} />{' '}
			{className && <MinimizeIcon className={classes.submenuIcon} />}
			<div className={classes.iconArrowBlock}>
				{item.submenu && (openSubmenuId === item.id ? <ExpandLess /> : <ExpandMore />)}
			</div>
		</ListItem>
	);
}

OneListItem.propTypes = {
	path: PropTypes.string,
	name: PropTypes.string,
	id: PropTypes.number
};
