import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import ListItemWithSubmenu from './ListItemWithSubmenu';
import OneListItem from './OneListItem';
import history from '../../../history';

import { menuList } from '../data';

const styles = theme => ({
	menuItem: {
		paddingLeft: '24px'
	},
	submenuItem: {
		position: 'relative',
		paddingLeft: '80px'
	},
	submenuIcon: {
		position: 'absolute',
		top: '2px',
		left: '42px',
		color: '#757575'
	},
	iconArrowBlock: {
		display: 'flex'
	}
});

class MainListItems extends Component {
	logOut = () => {
		sessionStorage.setItem('userLogin', false);
		history.push('/');
	};




	render() {
		const { classes } = this.props;
		return (
			<List>
				{menuList.map(item =>
					item.submenu ? (
						<ListItemWithSubmenu
							key={item.id}
							item={item}
							classes={classes}
							changeOpenSubmenuId={this.props.changeOpenSubmenuId}
							openSubmenuId={this.props.openSubmenuId}
						/>
					) : (
						<OneListItem key={item.id} classes={classes} item={item} />
					)
				)}

				<ListItem button onClick={this.logOut} className={classes.menuItem}>
					<ListItemIcon>
						<ExitToAppIcon />
					</ListItemIcon>

					<ListItemText primary={'Logout'} />
				</ListItem>
			</List>
		);
	}
}

MainListItems.propTypes = {
	classes: PropTypes.shape({ menuItem: PropTypes.string })
};



export default withStyles(styles)(MainListItems);
