import {handleFetch} from "../util/fetch";

const initialState = {
	categoryList: [],
};

const GET_CATEGORY_LIST = "GET_CATEGORY_LIST";


export const getCategoryList = () => dispatch => {
	handleFetch("/category", "GET")
		.then(res => {
			if (res.success) {
				dispatch({
					type: GET_CATEGORY_LIST,
					res,
				});
			} else throw res.payload;
		})
		.catch(err => console.log("GET_CATEGORY_LIST_FAILED", err));
};

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_CATEGORY_LIST:
			return {
				...state,
				categoryList: action.res.categories,
			};

		default:
			return state;
	}
};
