import { handleUpload, handleFetch, handleFetchImg, handleDelete } from '../util/fetch';

let date = new Date().toLocaleDateString('ko-KR', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
});
let todayReplace = date.replace(/\. /g, '-');
let today = todayReplace.substring(0, todayReplace.toString().length - 1);

const resetEditBlogPostForm = {
    title: '',
    url: '',
    shortDescription: '',
    quote: '',
    content: '',
    selectIndex: '',
    authorName: '',
    authorPosition: '',
    pageUrl: 'https://firstbridge.io/',
    createDate: today,
    publishDate: today,
    mustRead: false,
    publish: false,
    urlCanonical: false,
    deleteAuthorPhoto: false,
    deleteCoverPhoto: false,
    deleteOpengraphPhoto: false,
    deletePhotoStatus: true,
    categoriesIds: [],
    canonicalCategory: '',
    opengraph: null,
    cover: null,
    fileIdDelete: false,
    authorPhoto: null,
    opengraphCrop: {
        aspect: 1.9,
        x: 0,
        y: 0,
        height: 100,
        width: Math.floor(100 * 1.9)
    },
    topCrop: {
        aspect: 16 / 9,
        x: 0,
        y: 0,
        height: 100,
        width: Math.floor(100 * (16 / 9))
    },
    previewCrop: {
        aspect: 3 / 2,
        x: 0,
        y: 0,
        height: 100,
        width: Math.floor(100 * (3 / 2))
    }
};

const initialState = {
    resetEditBlogPostForm,
    createBlogMessageState: false,
    errorMessage: ''
};

const GET_ARTICLE = 'GET_ARTICLE';
const HIDE_MESSAGE = 'HIDE_MESSAGE';
const CLEAR_EDIT_BLOG_POST = 'CLEAR_EDIT_BLOG_POST';
const DELETE_PHOTO_END = 'DELETE_PHOTO_END';
const DELETE_PHOTO_START = 'DELETE_PHOTO_START';

const SUBMIT_SAVE_CHANGES = 'SUBMIT_SAVE_CHANGES';
const SUBMIT_SAVE_CHANGES_ERROR = 'SUBMIT_SAVE_CHANGES_ERROR';

export const clearEditBlogPost = () => ({
    type: CLEAR_EDIT_BLOG_POST
});

export const hideCreateBlogMessage = () => ({
    type: HIDE_MESSAGE
});

export const saveNewBlogPost = (parameters, urlId) => dispatch => {
    const method = urlId ? 'PUT' : 'POST';
    handleUpload('/article', parameters, method)
        .then(res => {
            if (res.success) {
                dispatch({
                    type: SUBMIT_SAVE_CHANGES,
                    res
                });
                if (urlId) {
                    dispatch(getArticle(urlId));
                }
            } else throw res.payload;
        })
        .catch(err => dispatch({ type: SUBMIT_SAVE_CHANGES_ERROR, err }));
};

export const getCoverPhoto = id => {
    return handleFetchImg(`/file/${id}`, 'GET')
        .then(res => {
            return window.URL.createObjectURL(res);
        })
        .catch(err => console.log('GET_COVER_PHOTO_FAILED', err));
};

export const getOpengrafPhoto = id => {
    return handleFetchImg(`/file/${id}`, 'GET')
        .then(res => {
            return window.URL.createObjectURL(res);
        })
        .catch(err => console.log('GET_OPENGRAF_PHOTO_FAILED', err));
};

export const getAuthorPhoto = id => {
    return handleFetchImg(`/file/${id}`, 'GET')
        .then(res => {
            return window.URL.createObjectURL(res);
        })
        .catch(err => console.log('GET_AUTHOR_PHOTO_FAILED', err));
};

export const deletePhoto = id => dispatch => {
    handleDelete('/file', id)
        .then(res => {
            if (res.success) {
                return res.success;
            } else throw res.payload;
        })
        .catch(err => console.log('DELETE_PHOTO_ERROR', err));
};

export const getArticle = id => dispatch => {
    handleFetch(`/protected/article/${id}`, 'GET')
        .then(async res => {
            if (res.success) {
                let coverPhoto = res.article.coverId && (await getCoverPhoto(res.article.coverId));
                let opengraphPhoto =
                    res.article.opengraphId && (await getOpengrafPhoto(res.article.opengraphId));
                let authorPhoto =
                    res.article.author &&
                    res.article.author.fileId &&
                    (await getAuthorPhoto(res.article.author.fileId));
                return {
                    coverPhoto: coverPhoto,
                    opengraphPhoto: opengraphPhoto,
                    authorPhoto: authorPhoto,
                    res
                };
            } else throw res.payload;
        })
        .then(res => {
            dispatch({
                type: GET_ARTICLE,
                res
            });
        })
        .catch(err => console.log('GET_ARTICLE_FAILED', err));
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SUBMIT_SAVE_CHANGES:
            return {
                ...state,
                newBlogId: action.res.id,
                createBlogMessageVariant: 'success',
                createBlogMessageState: true,
                errorMessage: ''
            };

        case SUBMIT_SAVE_CHANGES_ERROR:
            return {
                ...state,
                createBlogMessageState: true,
                errorMessage: action.err,
                createBlogMessageVariant: 'error'
            };

        case HIDE_MESSAGE:
            return {
                ...state,
                createBlogMessageState: false,
                errorMessage: ''
            };

        case DELETE_PHOTO_START:
            return {
                ...state,
                deletePhotoStatus: false
            };

        case DELETE_PHOTO_END:
            return {
                ...state,
                deletePhotoStatus: true
            };

        case GET_ARTICLE:
            let categoriesSelected = [];
            if (action.res.res.article.categories) {
                action.res.res.article.categories.map(item => {
                    return (categoriesSelected = [...categoriesSelected, item.id]);
                });
            }
            action.res.res.article.categoriesIds = categoriesSelected;

            if (action.res.res.article.coverId) {
                action.res.res.article.cover = action.res.coverPhoto;
            }
            if (action.res.res.article.opengraphId) {
                action.res.res.article.opengraph = action.res.opengraphPhoto;
            }
            if (action.res.res.article.author) {
                action.res.res.article.authorPhoto = action.res.authorPhoto;
            }
            if (action.res.res.article.author) {
                action.res.res.article.authorName = action.res.res.article.author.name;
            }
            if (action.res.res.article.author) {
                action.res.res.article.authorPosition = action.res.res.article.author.position;
            }
            if (action.res.res.article.index) {
                action.res.res.article.selectIndex = action.res.res.article.index;
            }

            return {
                ...state,
                resetEditBlogPostForm: { ...state.resetEditBlogPostForm, ...action.res.res.article }
            };

        case CLEAR_EDIT_BLOG_POST:
            return {
                ...state,
                resetEditBlogPostForm: resetEditBlogPostForm,
                createBlogMessageState: false
            };

        default:
            return state;
    }
};
