import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

class AlertDialogMessage extends React.Component {
	handleClickOpen = () => {
		this.props.agreeClick();
		this.props.close();
	};

	handleClose = () => {
		this.props.close();
	};

	render() {
		return (
			<div>
				<Dialog
					open={this.props.open}
					onClose={this.handleClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
					<DialogActions>
						<Button onClick={this.handleClose} color="primary" type="submit" variant="contained">
							No
						</Button>
						<Button onClick={this.handleClickOpen} color="primary" type="submit" variant="contained"
						        autoFocus>
							Yes
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

export default AlertDialogMessage;