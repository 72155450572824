import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';

function DefaultMaterialSelect({
    className,
    input,
    label,
    meta: { touched, error, invalid },
    children,
    classes,
    multiple,
    required,
    handleChange,
    multipleArray,
    shrink,
    disableSelect
}) {
    let errorText = '';
    let isError = false;

    if (error && touched) {
        errorText = <FormHelperText>{error}</FormHelperText>;
        isError = true;
    }

    if (!error && touched) {
        isError = false;
    }
    if (multiple && typeof input.value === 'string') {
        input.value = [];
    }

    const idToValue = selected => {
        return selected.map(item => {
            return multipleArray.find(x => x.id === item).name;
        });
    };

    return (
        <FormControl
            fullWidth={true}
            className={`${className ? className : ''} ${classes.input}`}
            error={isError}
            required={required}
        >
            <InputLabel shrink={shrink}>{label}</InputLabel>
            <Select
                {...input}
                disabled={disableSelect}
                input={multiple && <Input id="select-multiple-checkbox" />}
                onChange={input.onChange}
                multiple={multiple}
                renderValue={multipleArray && (selected => idToValue(selected).join(', '))}
                children={children}
            />
            {errorText}
        </FormControl>
    );
}

export default DefaultMaterialSelect;
