import React from "react";
import ReactDOM from "react-dom";
import {Router} from "react-router-dom";
import history from './history';

import {Provider} from "react-redux";

import {store} from "./ducks";

import MainRoute from "./main-route";

ReactDOM.render(
	<Provider store={store}>
		<Router history={history}>
			<MainRoute/>
		</Router>
	</Provider>,
	document.getElementById('root')
);
