import React from "react";
import {connect} from "react-redux";
import {changePassword, changeEmail, submitLogin} from "../../ducks/signin";
import {withStyles} from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import history from "../../history";
import ErrorTypeText from "../errorTypeText";

import classNames from "classnames";

const styles = theme => ({
	signinPageContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		minHeight: "100vh",
	},
	message: {
		width: "100%",
		transition: "0.2s"
	},
	messageShow: {
		marginTop: "12px",
	},
	avatar: {
		margin: theme.spacing.unit,
		backgroundColor: theme.palette.secondary.main
	},
	paper: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		maxWidth: "400px",
		width: "100%",
		padding: "20px"
	},
	btnLogin: {
		marginTop: "15px",
	},
	form: {
		width: "100%"
	}
});

class SignIn extends React.Component {
	onSubmit = () => {
		const {signin: {email, password}} = this.props;
		this.props.submitLogin(email, password);
	};

	onEmailChange = e => this.props.changeEmail(e.target.value);

	onPasswordChange = e => this.props.changePassword(e.target.value);

	componentWillMount() {
		if (sessionStorage.getItem("userLogin") === "true") {
			return history.push("/blog");
		}
	}

	componentDidUpdate() {
		if (sessionStorage.getItem("userLogin") === "true") {
			return history.push("/blog");
		}
	}

	render() {
		const {
			signin: {
				failed,
				error,
				email,
				password,
			},
			classes
		} = this.props;
		return (
			<div className={classes.signinPageContainer}>
				<Paper className={classes.paper}>
					<Avatar className={classes.avatar}>
						<LockOutlinedIcon/>
					</Avatar>
					<Typography component="h1" variant="h4">
						Login
					</Typography>


					<form className={classes.form} noValidate autoComplete="off">
						<TextField
							error={failed}
							label="Email"
							value={email}
							fullWidth
							onChange={this.onEmailChange}
							margin={"normal"}
						/>
						<TextField
							error={failed}
							type={"password"}
							label="Password"
							value={password}
							fullWidth
							aria-describedby="component-error-text"
							onChange={this.onPasswordChange}
							margin={"normal"}
						/>
					</form>
					<Typography component="p" variant="body1"
					            className={classNames(classes.message, failed ? classes.messageShow : '')}
					            color={"error"}>
						{failed && <ErrorTypeText errorText={error.toString()} />}
					</Typography>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						className={classes.btnLogin}
						onClick={this.onSubmit}
					>
						Submit
					</Button>
				</Paper>
			</div>
		);
	}
}

const mapStateToprops = state => ({
	signin: state.signin,
});

const mapDispatchToProps = {
	changePassword,
	changeEmail,
	submitLogin
};

export default withStyles(styles)(connect(mapStateToprops, mapDispatchToProps)(SignIn));
