import { handleFetch } from '../util/fetch';

const initialState = {
    categoryBlogList: []
};

const GET_CATEGORY_BLOG_PAGE = 'GET_CATEGORY_BLOG_PAGE';

export const getCategoryBlogPage = () => dispatch => {
    handleFetch('/category', 'GET')
        .then(res => {
            if (res.success) {
                dispatch({
                    type: GET_CATEGORY_BLOG_PAGE,
                    res
                });
            } else throw res.payload;
        })
        .catch(err => console.log('GET_CATEGORY_BLOG_PAGE_FAILED', err));
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CATEGORY_BLOG_PAGE:
            return {
                ...state,
                categoryBlogList: action.res.categories
            };

        default:
            return state;
    }
};
