import React, {Component} from "react";
import {connect} from "react-redux";
import {getCategoryList} from "../../ducks/getCategoryList";
import CategoryList from "./components/categoryList";

class CategoryListContainer extends Component {
	componentWillMount() {
		this.props.getCategoryList();
	}

	render() {
		const {categoryList} = this.props;
		return (
			<CategoryList categoryList={categoryList}/>
		);
	}
}

const mapStateToProps = state => ({
	categoryList: state.getCategoryList.categoryList
});
const mapDispathToProps = {
	getCategoryList,
};

export default connect(mapStateToProps, mapDispathToProps)(CategoryListContainer);