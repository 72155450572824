import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, change, formValueSelector } from 'redux-form';

import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import DefaultMaterialInput from '../../../componentsGlobal/DefaultMaterialInput';
import DefaultCheckbox from '../../../componentsGlobal/DefaultCheckbox';
import { deletePhoto } from '../../../ducks/createNewArticle';

const userIcon = require('../../../images/user.png');

const styles = theme => ({
    checkbox: {
        marginTop: theme.spacing.unit * 2
    },
    input: {
        marginBottom: theme.spacing.unit * 2
    },
    authorImg: {
        width: '100%',
        paddingTop: 'calc(100% - 4px)',
        borderRadius: '100%',
        overflow: 'hidden',
        border: '2px solid #ccc',
        backgroundSize: '60%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        marginBottom: '20px',
        marginTop: '12px'
    },
    authorImgFullSize: {
        backgroundSize: 'cover'
    },
    authorFileInput: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        opacity: 0,
        cursor: 'pointer'
    },
    deleteButton: {
        marginBottom: '10px'
    }
});

class CreateBlogPostRight extends Component {
    createAuthorImage = event => {
        this.props.change('CreateBlogPostForm', 'authorPhoto', event.target.files[0]);
    };

    deleteAuthorImage = event => {
        this.props.change('CreateBlogPostForm', 'authorPhoto', null);
        this.inputTypeFile.value = null;
        if (this.props.author && this.props.author.fileId && !this.props.fileIdDelete) {
            this.props.deletePhoto(this.props.author.fileId);
            this.props.change('CreateBlogPostForm', 'fileIdDelete', true);
        }
    };

    render() {
        const { classes, authorPhoto } = this.props;

        const renderAuthorPhoto =
            authorPhoto &&
            (typeof authorPhoto === 'object' ? URL.createObjectURL(authorPhoto) : authorPhoto);

        return (
            <Fragment>
                <Field
                    name="createDate"
                    type="date"
                    labelText="Created at"
                    shrink
                    component={DefaultMaterialInput}
                />
                <Field
                    name="publishDate"
                    type="date"
                    labelText="Published at"
                    shrink
                    component={DefaultMaterialInput}
                />
                <Field
                    name="authorName"
                    required
                    type="text"
                    labelText="Author name"
                    component={DefaultMaterialInput}
                    onChange={this.changePageTitle}
                />
                <Field
                    name="authorPosition"
                    required
                    type="text"
                    labelText="Author position"
                    component={DefaultMaterialInput}
                    onChange={this.changePageTitle}
                />
                <Typography component={'h4'} variant={'h6'}>
                    Author photo
                </Typography>
                <div
                    className={classNames(
                        classes.authorImg,
                        renderAuthorPhoto ? classes.authorImgFullSize : ''
                    )}
                    style={{
                        backgroundImage: `url(${renderAuthorPhoto ? renderAuthorPhoto : userIcon})`
                    }}
                />
                {renderAuthorPhoto && (
                    <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={this.deleteAuthorImage}
                        className={classes.deleteButton}
                    >
                        Delete
                    </Button>
                )}
                <Button fullWidth type="submit" variant="contained" color="primary">
                    <input
                        type="file"
                        ref={el => (this.inputTypeFile = el)}
                        onChange={this.createAuthorImage}
                        className={classes.authorFileInput}
                    />
                    {renderAuthorPhoto ? 'Change image' : 'Upload'}
                </Button>
                <div className={classes.checkbox}>
                    <Field name="mustRead" component={DefaultCheckbox} label="CHECK OUT OUR BLOG" />
                </div>
            </Fragment>
        );
    }
}

const selector = formValueSelector('CreateBlogPostForm');

const mapStateToprops = state => ({
    authorPhoto: selector(state, 'authorPhoto'),
    author: selector(state, 'author'),
    fileIdDelete: selector(state, 'fileIdDelete')
});

const mapDispatchToPros = {
    change,
    deletePhoto
};

export default withStyles(styles)(
    connect(
        mapStateToprops,
        mapDispatchToPros
    )(CreateBlogPostRight)
);
