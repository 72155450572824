export default function ErrorTypeText({errorText}) {

	const text = () => {
		switch (errorText.toString()) {
			case "URL_NOT_UNIQUE":
				return "Url is no unique";
			case "FILE_NOT_FOUND":
				return "Author file not found";
			case "AUTHOR_PHOTO_NOT_VALID":
				return "Author photo not valid";
			case "EMAIL_NOT_VALID":
				return "Email not valid";
			case "EMAIL_NOT_VALID,PASSWORD_NOT_VALID":
				return "Email and password not valid";
			case "PUBLISH_DATE_NOT_VALID":
			    return "Publish date not valid";
            case "CREATE_DATE_NOT_VALIDPUBLISH_DATE_NOT_VALID":
			    return "Publish and create date not valid";
            case "CREATE_DATE_NOT_VALID":
                return "Create date not valid";
			case "PASSWORD_NOT_VALID":
				return "Password not valid";
			case "COVER_PHOTO_NOT_VALID":
                return "Photo size exceeds allowable size";
            case "AUTH_ERROR":
				return "Email or password are wrong.";


			default:
				return errorText;
		}
	};

	return (
		errorText.length > 0 && text()
	);
}
