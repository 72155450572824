import React, { Component } from 'react';
import ReactCrop from 'react-image-crop';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import 'react-image-crop/dist/ReactCrop.css';

const coverIcon = require('../../../images/cover3.png');

const styles = theme => ({
    coverBlock: {
        marginBottom: '20px'
    },
    cropContainer: {
        marginBottom: '15px'
    },
    ogImg: {
        width: '100%',
        height: '170px',
        overflow: 'hidden',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        marginBottom: '10px',
        marginTop: '14px'
    },
    inputForCrop: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        opacity: '0',
        cursor: 'pointer',
        zIndex: 2
    },
    btnsBlock: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    deleteButton: {
        marginTop: '10px'
    }
});

class ImgOpengrafCrop extends Component {
    imageHeight;
    imageWidth;
    state = {
        updatedWithTrueValues: false,
        coverExists: false,
        opengraphCrop: {
            x: 0,
            y: 0,
            width: 100,
            aspect: 1.9
        }
    };

    getPercents = pixelCrop => {
        const percentCrop = { ...pixelCrop };
        percentCrop.width = (pixelCrop.width / this.imageWidth) * 100;
        percentCrop.height = (pixelCrop.height / this.imageHeight) * 100;
        percentCrop.x = (pixelCrop.x / this.imageWidth) * 100;
        percentCrop.y = (pixelCrop.y / this.imageHeight) * 100;
        percentCrop.aspect = 1.9;
        return percentCrop;
    };

    onImageLoaded = image => {
        this.imageRef = image;
        this.imageHeight = this.imageRef.naturalHeight;
        this.imageWidth = this.imageRef.naturalWidth;
    };
    onOpengraphCropBlur = () => {
        this.props.change('CreateBlogPostForm', 'opengraphCrop', this.state.opengraphPixelCrop);
    };
    onCropChangeOgPhoto = (crop, pixelCrop) => {
        if (!this.state.coverExists) {
            this.setState({ coverExists: true });
            return;
        }
        !!pixelCrop.height &&
            !!pixelCrop.width &&
            this.setState({ opengraphCrop: crop, opengraphPixelCrop: pixelCrop });
    };

    componentDidUpdate() {
        const { opengraphCrop } = this.props;
        if (!this.state.updatedWithTrueValues && this.imageHeight && this.imageWidth) {
            this.setState({
                updatedWithTrueValues: true,
                opengraphCrop: this.getPercents(opengraphCrop)
            });
        }
    }

    deleteOpengrafImg = () => {
        this.props.change('CreateBlogPostForm', 'opengraph', null);
        this.inputTypeFile.value = null;
        if (this.props.opengraphId) {
            this.props.deletePhoto(this.props.opengraphId);
            this.props.change('CreateBlogPostForm', 'opengraphId', false);
        }
    };

    render() {
        const { opengraphCrop } = this.state;
        const { classes } = this.props;

        const inputFile = this.props.input.value;
        const opengraphSrc =
            inputFile &&
            (typeof inputFile === 'object' ? URL.createObjectURL(inputFile) : inputFile);

        return (
            <div className={classes.coverBlock}>
                <Typography component={'h4'} variant={'h5'}>
                    {this.props.title}
                </Typography>

                {opengraphSrc ? (
                    <div onBlur={this.onOpengraphCropBlur} className={classes.cropContainer}>
                        <ReactCrop
                            src={opengraphSrc}
                            crop={opengraphCrop}
                            onImageLoaded={this.onImageLoaded}
                            onChange={this.onCropChangeOgPhoto}
                        />
                    </div>
                ) : (
                    <div
                        className={classes.ogImg}
                        style={{
                            backgroundImage: `url(${opengraphSrc || coverIcon})`
                        }}
                    />
                )}

                <Button type="submit" variant="contained" color="primary" fullWidth>
                    <input
                        type="file"
                        onChange={e => this.props.input.onChange(e.target.files[0])}
                        ref={el => (this.inputTypeFile = el)}
                        className={classes.inputForCrop}
                    />
                    {opengraphSrc ? 'Change image' : 'Upload'}
                </Button>
                {opengraphSrc && (
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={this.deleteOpengrafImg}
                        className={classes.deleteButton}
                    >
                        Delete
                    </Button>
                )}
            </div>
        );
    }
}

export default withStyles(styles)(ImgOpengrafCrop);
