import React, {Component, Fragment} from "react";
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing.unit * 3,
		overflowX: 'auto',
	},
	table: {
		minWidth: 700,
	},
});

class SingleViewForm extends Component {
	render() {
		const {formHistory, classes} = this.props;
		return (
			<Fragment>
				{
					formHistory && formHistory.length > 0 ?
						<Paper className={classes.root}>
							<Table className={classes.table}>
								<TableHead>
									<TableRow>
										<TableCell>Date/Time send</TableCell>
										<TableCell>Сlient Name</TableCell>
										<TableCell>Client Email</TableCell>
										<TableCell>Client's Project</TableCell>
										<TableCell>Functionality</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{formHistory.map(row =>
										<TableRow key={row.id}>
											<TableCell>{row.id}</TableCell>
											<TableCell>{row.description}</TableCell>
											<TableCell>{0}</TableCell>
											<TableCell>{0}</TableCell>
											<TableCell>{0}</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</Paper>
						:
						<h2>Have`t history now.</h2>
				}
			</Fragment>

		);
	}
}

export default withStyles(styles)(SingleViewForm)

