import { handleFetch } from '../util/fetch';

const CODE_HIDE_PRELOADER = 'CODE_HIDE_PRELOADER';
const CODE_SHOW_PRELOADER = 'CODE_SHOW_PRELOADER';
const SUBMIT_FORM = 'SUBMIT_FORM';
const GET_FORM_DATA = 'GET_FORM_DATA';
const SUCCESS_NOTIFICATION = 'SUCCESS_NOTIFICATION';
const FAILURE_NOTIFICATION = 'FAILURE_NOTIFICATION';
const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';

const initialState = {
    loader: true,
    headerCode: null,
    footerCode: null
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CODE_SHOW_PRELOADER:
            return {
                ...state,
                loader: true
            };
        case CODE_HIDE_PRELOADER:
            return {
                ...state,
                loader: false
            };
        case SUBMIT_FORM:
        case GET_FORM_DATA:
        case SUCCESS_NOTIFICATION:
        case FAILURE_NOTIFICATION:
        case HIDE_NOTIFICATION:
            return {
                ...state,
                ...action.payload
            };

        default:
            return state;
    }
};

export function successNotification() {
    return {
        type: SUCCESS_NOTIFICATION,
        payload: {
            variant: 'success',
            showNotification: true,
            notification: 'Custom codes successfully added/updated.'
        }
    };
}

export function failureNotification(err) {
    return {
        type: FAILURE_NOTIFICATION,
        payload: {
            variant: 'error',
            showNotification: true,
            notification: err.message || 'Error! Codes not added/updated.'
        }
    };
}

export function hideNotification() {
    return {
        type: FAILURE_NOTIFICATION,
        payload: {
            showNotification: false,
            notification: ''
        }
    };
}

export function handleSubmitForm(formData) {
    return async (dispatch, getState) => {
        const { headerCode: newHeaderCode, footerCode: newFooterCode } = formData;
        const {
            customCodes: { headerCode, footerCode }
        } = getState();

        let headerSuccess = null;
        let footerSuccess = null;

        try {
            dispatch({ type: CODE_SHOW_PRELOADER });
            if (newHeaderCode !== headerCode) {
                const res = await handleFetch('/custom/header', 'POST', { content: newHeaderCode });
                headerSuccess = res.success;
            }
            if (newFooterCode !== footerCode) {
                const res = await handleFetch('/custom/footer', 'POST', { content: newFooterCode });
                footerSuccess = res.success;
            }
            const updatedFormData = {
                loader: false,
                headerCode:
                    headerSuccess && newHeaderCode !== undefined ? newHeaderCode : headerCode,
                footerCode:
                    footerSuccess && newFooterCode !== undefined ? newFooterCode : footerCode
            };

            dispatch({ type: SUBMIT_FORM, payload: updatedFormData });
            dispatch(successNotification());
        } catch (err) {
            dispatch(failureNotification(err));
            dispatch({ type: CODE_HIDE_PRELOADER });
        }
    };
}

export function getFormData() {
    return async (dispatch, getState) => {
        const footer = await handleFetch('/custom/footer');
        const header = await handleFetch('/custom/header');

        dispatch({
            type: GET_FORM_DATA,
            payload: {
                loader: false,
                headerCode: header.code,
                footerCode: footer.code
            }
        });
    };
}
