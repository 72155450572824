import React, {Component, Fragment} from "react";
import {Link} from "react-router-dom";
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import AlertDialogMessage from "../../AlertDialogMessage";
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing.unit * 3,
		overflowX: 'auto',
		marginBottom: '30px',
	},
	table: {
		minWidth: 700,
	},
	icon: {
		cursor: "pointer",
		transition: "0.2s",
		marginLeft: "10px",
		'&:hover': {
			color: "#001cb8",
		}
	},
	tableHeadTd: {
		padding: "10px 50px 10px 25px",
		maxWidth: "300px",
		minWidth: "100px",
		position: "relative",
	},
});


class ViewForm extends Component {
	onDeleteClick = (e) => {
		this.props.showDeleteFormMessageState();
		this.setState({deleteElementId: e});
	};

	render() {
		const {
			tableContent, classes, buttonText, buttonLink, tableHeader,
			deleteFormMessageState, hideDeleteFormMessageState, deleteForm,
		} = this.props;

		return (
			<Fragment>

				{
					deleteFormMessageState &&
					<AlertDialogMessage
						open={deleteFormMessageState}
						close={hideDeleteFormMessageState}
						title={"Do you want to delete the article?"}
						agreeClick={() => deleteForm(this.state.deleteElementId)}
					/>
				}


				{
					buttonText &&
					<Link to={buttonLink}>
						<Button
							type="submit"
							variant="contained"
							color="primary"
							margin={"normal"}
						>
							{buttonText}
						</Button>
					</Link>
				}
				<Paper className={classes.root}>
					<Table className={classes.table}>
						<TableHead>
							<TableRow>
								{
									tableHeader.map(item =>
										<TableCell align={item.align} key={item.id}
										           className={classes.tableHeadTd}>{item.title}</TableCell>
									)
								}
							</TableRow>
						</TableHead>
						<TableBody>
							{tableContent && tableContent.map((row, index) => (
								<TableRow key={row.id}>
									<TableCell className={classes.tableHeadTd} component="th" scope="row">
										{row.id}
									</TableCell>
									<TableCell className={classes.tableHeadTd}>
										{row.description}
									</TableCell>
									<TableCell className={classes.tableHeadTd}>
										{row.count}
									</TableCell>
									<TableCell className={classes.tableHeadTd} align="right">
										<Delete color="primary" className={classes.icon}
										        onClick={() => this.onDeleteClick(row.id)}/>
										<Link to={`/forms/edit/${row.id}`}>
											<Edit color="primary" className={classes.icon}/>
										</Link>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</Paper>
			</Fragment>
		);
	}
}

export default withStyles(styles)(ViewForm);
