import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change, formValueSelector } from 'redux-form';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    title: {
        flex: 1,
        paddingRight: '20px'
    },
    blogSingleHeader: {
        display: 'flex',
        justifyContent: 'space-between'
    }
});

class createBlogPostHeader extends Component {
    render() {
        const { classes, title } = this.props;

        return (
            <div className={classes.blogSingleHeader}>
                <Typography component="div" variant="h4" color="inherit" className={classes.title}>
                    {title && title.length > 0 ? title : 'Create blog post'}
                </Typography>
            </div>
        );
    }
}

const selector = formValueSelector('CreateBlogPostForm');
const mapStateToProps = state => ({
    publish: selector(state, 'publish'),
    title: state.createNewArticle.resetEditBlogPostForm.title
});

const mapDispatchToPros = {
    change
};

export default connect(
    mapStateToProps,
    mapDispatchToPros
)(withStyles(styles)(createBlogPostHeader));
