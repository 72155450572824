import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { reduxForm, Form, formValueSelector, change } from 'redux-form';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import PublishIcon from '@material-ui/icons/Publish';
import CircularProgress from '@material-ui/core/CircularProgress';

import { articleValidate } from '../ValidationReduxForms';
import ErrorTypeText from '../errorTypeText';
import Notification from '../Notification';
import history from '../../history';

import {
    saveNewBlogPost,
    hideCreateBlogMessage,
    getArticle,
    clearEditBlogPost,
    deletePhoto
} from '../../ducks/createNewArticle';

import CreateBlogPostHeader from './components/createBlogPostHeader';
import CreateBlogPostLeft from './components/createBlogPostLeft';
import CreateBlogPostRight from './components/createBlogPostRight';
import CreateBlogPostCenter from './components/createBlogPostCenter';

const styles = theme => ({
    blogSingleContainer: {
        alignItems: 'stretch'
    },
    paper: {
        padding: theme.spacing.unit * 2,
        color: theme.palette.text.secondary,
        minHeight: '100%'
    },
    blogSingleFooter: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    publishIcon: {
        marginRight: theme.spacing.unit
    },
    status: {
        marginRight: '20px'
    },
    statusBtn: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    spinnerContainer: {
        width: '100%'
    },
    spinner: {
        position: 'relative',
        left: '42%',
        marginTop: '30vh'
    }
});

function scrollToTop(scrollDuration) {
    let scrollStep = -window.scrollY / (scrollDuration / 15),
        scrollInterval = setInterval(function() {
            if (window.scrollY !== 0) {
                window.scrollBy(0, scrollStep);
            } else clearInterval(scrollInterval);
        }, 15);
}

class CreateBlogPost extends React.Component {
    state = {
        publishDisabled: true,
        editArticle: false,
        articleDataLoading: false
    };
    saveChanges = values => {
        const { publish } = this.props;
        const { id } = this.props.match.params;
        values.topCrop.aspect = 1.7777777;
        values.previewCrop.aspect = 0.75;

        if (this.props.author) {
            values.authorId = this.props.author.id;
        }
        this.setState({ publishDisabled: !!publish });
        this.props.saveNewBlogPost(values, id);
    };

    componentDidMount() {
        const { id } = this.props.match.params;
        if (id) {
            this.props.getArticle(id);
            this.setState({ editArticle: true, articleDataLoading: true });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.newBlogId !== this.props.newBlogId) {
            this.props.newBlogId && history.push(`/blog/edit/${this.props.newBlogId}`);
            this.props.newBlogId && this.props.getArticle(this.props.newBlogId);
        }
        if (prevProps.initialValues.title !== this.props.initialValues.title) {
            this.setState({ articleDataLoading: false });
        }
        if (prevProps.publish === false && this.props.publish === true) {
            this.setState({ editArticle: false });
        }
    }

    componentWillUnmount() {
        this.props.clearEditBlogPost();
    }

    changePublishStatus = () => {
        const { change, valid } = this.props;
        valid && change('publish', true);
    };

    render() {
        const {
            classes,
            handleSubmit,
            createBlogMessageState,
            hideCreateBlogMessage,
            errorMessage,
            publish,
            pristine,
            createBlogMessageVariant,
            touch
        } = this.props;

        return this.state.articleDataLoading ? (
            <div className={classes.spinnerContainer}>
                <CircularProgress className={classes.spinner} size={100} />
            </div>
        ) : (
            <React.Fragment>
                <Form onSubmit={handleSubmit(value => this.saveChanges(value))} noValidate={true}>
                    <Grid container spacing={24} className={classes.blogSingleContainer}>
                        <Grid item xs={12}>
                            <CreateBlogPostHeader />
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <Paper className={classes.paper}>
                                <CreateBlogPostLeft touch={touch} />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Paper className={classes.paper}>
                                <CreateBlogPostRight />
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <CreateBlogPostCenter />
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper className={classNames(classes.paper, classes.blogSingleFooter)}>
                                <Link to={'/blog'}>
                                    <Button variant="contained" color="primary">
                                        Cancel
                                    </Button>
                                </Link>
                                <div className={classes.statusBtn}>
                                    <div className={classes.status}>
                                        Status:{' '}
                                        <Typography
                                            inline
                                            component="span"
                                            variant="h6"
                                            color={publish ? 'primary' : 'error'}
                                        >
                                            {publish ? 'Publish' : 'Draft'}
                                        </Typography>
                                    </div>
                                    <Button
                                        type="button"
                                        variant="contained"
                                        color="primary"
                                        disabled={pristine || publish}
                                        onClick={handleSubmit(value => this.saveChanges(value))}
                                    >
                                        Save Draft
                                    </Button>
                                </div>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={
                                        pristine &&
                                        this.state.publishDisabled &&
                                        !this.state.editArticle
                                    }
                                    onClick={() => {
                                        !publish && this.changePublishStatus();
                                        scrollToTop(500);
                                    }}
                                >
                                    <PublishIcon className={classes.publishIcon} />
                                    {publish ? 'Update' : 'Publish'}
                                </Button>
                            </Paper>
                        </Grid>
                    </Grid>
                </Form>
                <Notification
                    openState={createBlogMessageState}
                    variant={createBlogMessageVariant || 'success'}
                    close={hideCreateBlogMessage}
                    messageText={
                        !!errorMessage ? (
                            <ErrorTypeText errorText={errorMessage} />
                        ) : (
                            'Congratulation!!! You save fixes.'
                        )
                    }
                />
            </React.Fragment>
        );
    }
}

const selector = formValueSelector('CreateBlogPostForm');

const mapStateToProps = state => ({
    title: state.createNewArticle.resetEditBlogPostForm.title,
    createBlogMessageState: state.createNewArticle.createBlogMessageState,
    createBlogMessageVariant: state.createNewArticle.createBlogMessageVariant,
    errorMessage: state.createNewArticle.errorMessage,
    initialValues: state.createNewArticle.resetEditBlogPostForm,
    newBlogId: state.createNewArticle.newBlogId,
    categorieForCanonicalUrl: state.createNewArticle.resetEditBlogPostForm.categorieForCanonicalUrl,
    author: selector(state, 'author'),
    publish: selector(state, 'publish')
});

const mapDispatchToPros = {
    saveNewBlogPost,
    hideCreateBlogMessage,
    getArticle,
    clearEditBlogPost,
    deletePhoto,
    change
};

export default compose(
    withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToPros
    ),
    reduxForm({
        form: 'CreateBlogPostForm',
        enableReinitialize: true,
        validate: articleValidate
    })
)(CreateBlogPost);
