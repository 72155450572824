import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, Field, Form } from 'redux-form';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles/index';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import DefaultMaterialInput from '../../componentsGlobal/DefaultMaterialInput';
import Notification from '../Notification';
import { getFormData, handleSubmitForm, hideNotification } from '../../ducks/addCustomCodes';

const styles = {
    spinnerStyle: {
        margin: '50px'
    }
};

class CustomCodesPage extends Component {
    componentDidMount() {
        const { getFormData } = this.props;
        getFormData();
    }

    submit = value => {
        const { handleSubmitForm } = this.props;
        handleSubmitForm(value);
    };

    closeNotification = () => {
        const { hideNotification } = this.props;
        hideNotification();
    };

    render() {
        const {
            handleSubmit,
            submitting,
            loader,
            showNotification,
            notification,
            variant,
            pristine
        } = this.props;

        return (
            <div>
                <Form onSubmit={handleSubmit(this.submit)} noValidate={true}>
                    {loader ? (
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <CircularProgress style={styles.spinnerStyle} />
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Field
                                    name="headerCode"
                                    type="text"
                                    labelText="Head Code"
                                    multiline
                                    rows={6}
                                    rowsMax={15}
                                    component={DefaultMaterialInput}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    name="footerCode"
                                    type="text"
                                    labelText="Footer Code"
                                    multiline
                                    rows={6}
                                    rowsMax={15}
                                    component={DefaultMaterialInput}
                                />
                            </Grid>
                        </Grid>
                    )}
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={pristine || submitting}
                    >
                        Submit all changes
                    </Button>
                </Form>
                <Notification
                    openState={showNotification}
                    messageText={notification}
                    variant={variant}
                    close={this.closeNotification}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loader: state.customCodes.loader,
    initialValues: state.customCodes,
    showNotification: state.customCodes.showNotification,
    notification: state.customCodes.notification,
    variant: state.customCodes.variant
});

const mapDispatchToPros = {
    handleSubmitForm,
    getFormData,
    hideNotification
};

export default compose(
    withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToPros
    ),
    reduxForm({
        form: 'CustomCodes',
        enableReinitialize: true
    })
)(CustomCodesPage);
