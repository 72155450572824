import React, {Component, Fragment} from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import Button from "@material-ui/core/Button";
import {reduxForm, Field, Form} from 'redux-form';
import Grid from '@material-ui/core/Grid';

import DefaultMaterialInput from "../../../componentsGlobal/DefaultMaterialInput";
import {createForm, hideCongratulationFromMessage, getOneForm, clearCreateForm} from "../../../ducks/getAndCreateForms";
import Notification from "../../Notification";
import {validateCreateFrom} from "../../ValidationReduxForms";

class CreateEditForms extends Component {
	saveChanges = (values) => {
		this.props.createForm(values);
	};

	componentDidMount() {
		const {id} = this.props.params;
		if (id) {
			this.props.getOneForm(id);
		}
	}

	componentWillUnmount() {
		this.props.clearCreateForm();
	}

	render() {
		const {
			handleSubmit, submitting, congratulationFromMessage, hideCongratulationFromMessage
		} = this.props;
		const {id} = this.props.params;
		
		return (
			<Fragment>
				<Form onSubmit={handleSubmit(value => this.saveChanges(value))} noValidate={true}>
					<Grid container spacing={24}>
						<Grid item xs={12}>
							<Field
								name="description"
								type="text"
								labelText="Form name"
								component={DefaultMaterialInput}
								required
							/>
						</Grid>
					</Grid>
					<Button
						type="submit"
						variant="contained"
						color="primary"
						disabled={submitting}
					>
						{id ? "Save form" : "Add new form"}
					</Button>
				</Form>
				<Notification
					openState={congratulationFromMessage}
					close={hideCongratulationFromMessage}
					messageText={"Congratulation!!! You create new post."}
				/>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	forms: state.getAndCreateForms.forms,
	initialValues: state.getAndCreateForms.oneForm,
	congratulationFromMessage: state.getAndCreateForms.congratulationFromMessage,
});

const mapDispatchToPros = {
	createForm,
	hideCongratulationFromMessage,
	getOneForm,
	clearCreateForm
};

export default compose(
	connect(mapStateToProps, mapDispatchToPros),
	reduxForm({
		form: 'CreateForm',
		enableReinitialize: true,
		validate: validateCreateFrom,
	})
)(CreateEditForms);

