import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';

import { reducer as signin } from './signin';
import { reducer as getCategoryBlogPage } from './getCategoryBlogPage';
import { reducer as getCategoryList } from './getCategoryList';
import { reducer as getAndCreateForms } from './getAndCreateForms';
import { reducer as blogPosts } from './blogPosts';
import { reducer as createNewArticle } from './createNewArticle';
import { reducer as customCodes } from './addCustomCodes';

import history from '../history';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducer = combineReducers({
    router: connectRouter(history),
    signin,
    getCategoryBlogPage,
    blogPosts,
    getAndCreateForms,
    getCategoryList,
    createNewArticle,
    form: formReducer,
    customCodes
});

export const store = createStore(reducer, composeEnhancer(applyMiddleware(thunk)));
