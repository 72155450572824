import React, { Component } from "react";
import { connect } from "react-redux";
import {
	getArticleList
} from "../../ducks/blogPosts";
import BlogPage from "./components/blogPage";

class BlogPageContainer extends Component {
	componentDidMount() {
		this.props.getArticleList(1, 10);
	}

	render() {
		return (
			<BlogPage />
		);
	}
}

const mapStateToProps = state => ({});
const mapDispathToProps = {
	getArticleList,
};

export default connect(mapStateToProps, mapDispathToProps)(BlogPageContainer);