import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
// from material-ui
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

const styles = () => {
    return {
        field: {
            width: '100%',
            marginBottom: '20px'
        },
        urlBlock: {
            position: 'static'
        },
        urlLabel: {
            position: 'absolute',
            left: '0px',
            top: '0px'
        }
    };
};

function ErrorField(props) {
    const {
        input,
        placeholder,
        type,
        meta: { error, touched },
        classes,
        labelText,
        autoFocus,
        required,
        inputRef,
        disabled,
        fullWidth,
        multiline,
        shrink,
        rows,
        rowsMax
    } = props;

    let errorText = '';
    let isError = false;

    if (error && touched) {
        errorText = <FormHelperText>{error}</FormHelperText>;
        isError = true;
    }

    if (!error && touched) {
        isError = false;
    }
    return (
        <FormControl
            className={classNames(classes.field, input.name === 'url' && classes.urlBlock)}
            error={isError}
            required={required}
            fullWidth={fullWidth}
        >
            <InputLabel
                htmlFor={input.name}
                shrink={shrink}
                className={input.name === 'url' ? classes.urlLabel : ''}
            >
                {labelText}
            </InputLabel>
            <Input
                {...input}
                autoFocus={autoFocus}
                id={input.name}
                type={type}
                placeholder={placeholder}
                disabled={disabled}
                autoComplete="off"
                multiline={multiline}
                rows={rows}
                rowsMax={rowsMax}
                inputRef={inputRef}
            />
            {errorText}
        </FormControl>
    );
}

export default withStyles(styles)(ErrorField);
