import React, {Component} from "react";
import {withStyles} from '@material-ui/core/styles';

import DefaultTable from "../../../componentsGlobal/DefaultTable";

const tableHeader = [
	{
		id: 1,
		title: "#"
	},
	{
		id: 2,
		title: "Category"
	},
	{
		id: 3,
		title: "Status"
	},
	{
		id: 4,
		title: "Posts count"
	},
	{
		id: 5,
		title: "Actions",
		align: "right",
	}
];

const styles = theme => ({});

let araTest = [{
	"id": 6,
	"publish": false,
	"title": "213123",
	"createDate": "2019-03-25",
	"postsCount": 4
}, {
	"id": 5,
	"publish": false,
	"title": "karat2342",
	"createDate": "2019-03-25",
	"postsCount": 8
}, {
	"id": 7,
	"publish": false,
	"title": "mich",
	"createDate": "2019-03-25",
	"postsCount": 2,
	"submenu": [
		{
			"id": 6,
			"publish": false,
			"title": "ara",
			"createDate": "2019-03-25",
			"postsCount": 3
		}, {
			"id": 5,
			"publish": false,
			"title": "ara2",
			"createDate": "2019-03-25",
			"postsCount": 2
		},
	]
}, {
	"id": 8,
	"publish": false,
	"title": "sad sad as",
	"createDate": "2019-03-25",
	"postsCount": 1
}, {
	"id": 9, "publish": false, "title": "sd2q31", "createDate": "2019-03-25", "postsCount": 1, "submenu": [
		{
			"id": 6,
			"publish": false,
			"title": "ara",
			"createDate": "2019-03-25",
			"postsCount": 4
		}, {
			"id": 5,
			"publish": false,
			"title": "ara2",
			"createDate": "2019-03-25",
			"postsCount": 1
		},
	]
}];


class CategoryList extends Component {
	render() {

		return (
			<DefaultTable
				buttonText={"Add new category"}
				buttonLink={"/category-list"}
				tableHeader={tableHeader}
				tableContent={araTest}
				onClickEditIcon={() => {
				}}
				onClickDeleteIcon={() => {
				}}
			/>
		);
	}
}

export default withStyles(styles)(CategoryList);
