import React from "react";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const RenderCheckbox = ({ input, label, classes }) => (
	<div>
		<FormControlLabel
			className={classes}
			control={
				<Checkbox
					checked={input.value ? true : false}
					color={"primary"}
					onChange={input.onChange}
				/>
			}
			label={label}
		/>
	</div>
);

export default RenderCheckbox;