import React, {Component} from "react";
import {connect} from "react-redux";

import SingleViewForm from "./components/singleViewForm";
import {getFormHistory} from "../../ducks/getAndCreateForms";

class FormSinglePage extends Component {
	componentDidMount() {
		const {id} = this.props.match.params;
		if (id) {
			this.props.getFormHistory(id);
		}
	}

	render() {
		const {formHistory} = this.props;
		return (
			<SingleViewForm formHistory={formHistory}/>
		);
	}
}

const mapStateToProps = state => ({
	formHistory: state.getAndCreateForms.formHistory,
});
const mapDispatchToProps = {
	getFormHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormSinglePage);