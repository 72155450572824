import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import LayersIcon from '@material-ui/icons/Layers';
import SettingsIcon from '@material-ui/icons/Settings';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import StarIcon from '@material-ui/icons/Star';

export const menuList = [
    {
        name: 'Admin home',
        id: 1,
        icon: DashboardIcon
    },
    {
        name: 'Pages',
        id: 2,
        icon: ShoppingCartIcon
    },
    {
        path: '/category-list',
        name: 'Categories',
        id: 3,
        icon: StarIcon,
        submenu: [
            {
                path: '/blog',
                name: 'Blog',
                id: 3.2
            },
            {
                name: 'Cases',
                id: 3.3
            },
            {
                name: 'Expertise',
                id: 3.4
            }
        ]
    },
    {
        path: '/forms',
        name: 'Forms',
        id: 4,
        icon: BarChartIcon
    },
    {
        name: 'Users management',
        id: 5,
        icon: PeopleIcon
    },
    {
        name: 'Settings',
        id: 6,
        icon: SettingsIcon,
        submenu: [
            {
                name: 'Caching',
                id: 6.1
            },
            {
                name: 'SEO',
                id: 6.2
            },
            {
                path: '/custom-codes',
                name: 'Custom codes',
                id: 6.3
            },
            {
                name: 'Translations',
                id: 6.4
            },
            {
                name: 'Updates',
                id: 6.5
            }
        ]
    },
    {
        name: 'Estimations',
        id: 7,
        icon: AccessTimeIcon
    },
    {
        name: 'Dev tools',
        id: 8,
        icon: LayersIcon
    }
];
